<template>
  <div class="login-page">
    <img src="../img/loading.gif" class="loading-gif" alt="Loading..." />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/service/api'
import http from '@/service/axios'
import { setCookie } from '@/common/cookie'
import { getSystemName, getSystemRoutePath } from '@/common'
import { singleSignOnURL } from '@/config/config'
import { Modal } from 'ant-design-vue'

export default {
  mounted() {
    const query = this.$route.query

    if (query.code) {
      //单点登录回调
      localStorage.setItem('apiURL', query.gateway_uri)
      setTimeout(() => {
        this.oauthLogin(query.code)
      })
      return
    } else {
      window.location.href = singleSignOnURL
    }
  },
  data() {
    return {
      buList: [],
      menuList: [],
      jumpPage: '',
      currentApp: {},
      userSession: {},
    }
  },
  methods: {
    ...mapMutations(['setState']),
    oauthLogin(code) {
      http({
        url: api.authorizeOauth,
        type: 'post',
        data: {
          authCode: code,
        },
        success: (res) => {
          setCookie({ d_session_id: res.result })
          this.getSession(res.result)
        },
        fail: () => {
          window.location.href = location.origin
        },
      })
    },
    getSession() {
      http({
        url: api.getSession,
        type: 'post',
        data: {
          appCode: 'ERP',
        },
        success: async (res) => {
          let userSession = res.result
          this.userSession = userSession
          let subMerchantAppList = userSession.subMerchantAppList
          let currentApp = userSession.currentSubMerchantApp?.[0] || subMerchantAppList[0]
          this.currentApp = currentApp
          const data = {
            shipperList: userSession.shipperList,
            warehouseList: userSession.warehouseList,
            userAccount: userSession.currentUser,
            merchantName: userSession.merchantName,
            merchantCode: userSession.merchantCode,
            originalMerchantCode: userSession.originalMerchantCode,
            currentApp,
          }
          this.setState(data)
          await this.getSubMerchantAppList()
          if (!userSession.currentSubMerchantApp?.length) {
            this.switchApp(currentApp.appCode)
          }
          let systemName = getSystemName(currentApp.appCode)
          this.queryIdaasAppList(userSession.merchantCode)
          this.getShopList(userSession.merchantCode)
          this.setDefaultWarehouse(userSession.warehouseList)

          let routePath = getSystemRoutePath(currentApp.appCode)
          let pageCacheFullPath = localStorage.getItem('pageCacheFullPath')
          if (pageCacheFullPath) {
            routePath = pageCacheFullPath
            localStorage.removeItem('pageCacheFullPath')
          }
          this.$router.push(`/${systemName}/${routePath}`)
        },
        fail: (res) => {
          if (res.message === 'authCode is invalid!') {
            window.location.href = singleSignOnURL
          }
        },
      })
    },
    setParty(party) {
      party = party || this.userSession.buList[0]
      if (!party) {
        this.$message.warning(this.$t('业务组为空'))
        return
      }
      http({
        url: api.switchParty,
        type: 'post',
        data: { buId: party.departmentId || party.id },
        success: async (res) => {
          const userSession = res.result
          party = userSession.currentBu || party
          party.partyId = party.id
          party.partyName = party.name
          userSession.currentParty = party //接口换了 只好前端来兼容
          this.setState({
            userSession,
          })
        },
      })
    },
    switchApp(switchAppCode) {
      http({
        url: api.switchApp,
        type: 'post',
        data: {
          switchAppCode,
        },
      })
    },
    async getSubMerchantAppList() {
      let res = await http({
        url: api.getSubMerchantAppList,
        type: 'post',
      })
      this.setState({
        subMerchantAppList: res.result,
      })

      let app = res.result.find((item) => item.appCode === this.currentApp.appCode)
      this.userSession.funcList = app.funcList

      let party = this.userSession.currentBu || {}
      party.partyId = party.id
      party.partyName = party.name
      this.userSession.currentParty = party //接口换了 只好前端来兼容
      this.setState({
        menuList: app.menuList,
        userSession: this.userSession,
        userInfo: this.userSession,
      })
    },
    async queryIdaasAppList(merchantCode) {
      try {
        let res = await http({
          url: api.queryIdaasAppList,
          data: {
            merchantCode,
          },
        })
        let list = res.result,
          obj = {}
        list.forEach((item) => {
          obj[item.appCode] = item
        })
        localStorage.setItem('currentIdaasApp', JSON.stringify(obj))
        return Promise.resolve()
      } catch (err) {
        return Promise.resolve()
      }
    },
    async getShopList(merchantCode) {
      let res = await http({
        url: api.getNewShopInfoList,
        data: {
          status: 1,
          merchantCode,
        },
      })
      let list = res.result.list || []
      if (list.length > 0) {
        this.setState({
          shopList: list,
          currentShop: list[0],
        })
      }
      if (!this.userSession.currentBu) {
        this.setParty(list[0])
      }
      if (this.currentApp.appCode === 'SALE_MANAGE') {
        if (list.length === 0) {
          this.$router.push(`/OMS/shop_web`)
        } else {
          for (let i = 0; i < list.length; i++) {
            if (
              list[i].authStatus === 'AUTH_EXPIRED' ||
              (list[i].authStatus === 'AUTHED' && list[i].authExpiredDays < 5)
            ) {
              setTimeout(() => {
                Modal.confirm({
                  title: list[i].shopName + this.$t(`店铺授权快失效了，快去重新授权吧`),
                  okText: this.$t(`重新授权`),
                  cancelText: this.$t(`取消`),
                  onOk: () => {
                    localStorage.removeItem('partyList')
                    this.$router.push('/OMS/shop_web/shopManage?shopName=' + list[i].shopName)
                  },
                })
              })
              break
            }
          }
        }
      }
    },
    setDefaultWarehouse(warehouseList) {
      let defaultWarehouse
      if (!localStorage.getItem('defaultWarehouse')) {
        if (warehouseList.length > 0) {
          defaultWarehouse = warehouseList[0]
          defaultWarehouse.id = String(defaultWarehouse.id)
          localStorage.setItem('defaultWarehouse', JSON.stringify(defaultWarehouse))
        } else {
          localStorage.setItem('defaultWarehouse', '')
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.login-page {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .loading-gif {
    width: 100px;
  }
}
</style>
